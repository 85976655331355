import { render, staticRenderFns } from "./audition.vue?vue&type=template&id=64a85ede&scoped=true"
import script from "./audition.vue?vue&type=script&lang=js"
export * from "./audition.vue?vue&type=script&lang=js"
import style0 from "./audition.vue?vue&type=style&index=0&id=64a85ede&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a85ede",
  null
  
)

export default component.exports