<template>
  <!-- 免费视频 -->
  <div id="app">
    <div class="cson-container">
      <div class="audition-head">
        <div class="tag">
          <img class="logo" src="@/assets/img/audition-icon.png" />
          <h2>北美求职</h2>
          <p>专注于北美CS求职，FMAG世界级公司</p>
        </div>
        <div class="search">
          <img src="@/assets/img/search-icon.png" />
          <input
            v-model="keyword"
            placeholder="搜索感兴趣的视频"
            @input="searchLodash"
          />
        </div>
      </div>
      <div class="">
        <div class="auditionContent clearfix">
          <div
            v-for="(item, ii) in cursonsList"
            :key="ii"
            class="box"
            @click="goBroadcast(item)"
          >
            <img :src="item.cover" />
            <div class="title">{{ item.title }}</div>
            <span class="duration">{{ item.duration }}</span>
          </div>
          <div
            v-if="keyword != '' && total == 0"
            style="text-align: center; font-size: 20px"
          >
            未搜索到结果
          </div>
        </div>

        <div style="padding: 20px; text-align: center">
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            :current-page.sync="currentPage"
            :total="total"
            :page-size="12"
            layout="prev, pager, next"
            @current-change="getAuditions"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getAuditions } from "@/service/audition";
import _ from "lodash";
export default {
  data() {
    return {
      cursonsList: [],
      keyword: "",
      currentPage: 1,
      total: 0,
    };
  },
  computed: mapState(["userInfo"]),
  methods: {
    goBroadcast(item) {
      this.$router.push(`/auditionWatch?id=${item.id}`);
    },
    getAuditions(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getAuditions({
        keyWord: this.keyword,
        current: this.currentPage,
        size: 12,
      }).then((res) => {
        if (res.success) {
          this.cursonsList = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    searchLodash: _.debounce(function () {
      this.getAuditions(1);
    }, 1000),
  },
  mounted() {
    this.getAuditions();
  },
};
</script>

<style lang="css" scoped>
.curson .box {
  min-height: auto;
  padding-bottom: 0;
  width: auto;
  height: auto;
  box-shadow: none;
  margin: 10px 6px;
  border: none;
}

.curson .box:hover {
  border: none;
  box-shadow: none;
}

.tag {
  display: flex;
  align-items: center;
}
.auditionContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.box {
  width: 20%;
  margin-right: 6.66%;
  background: #fff;
  height: inherit;
  margin-bottom: 12px;
  cursor: pointer;
  transition: 0.1s;
  position: relative;
}
.box:nth-child(4n) {
  margin-right: 0;
}
.box:hover {
  transform: translateY(-10px);
}
.box img {
  width: 100%;
  border-radius: 4px;
}
.box img:hover {
  width: 100%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}
.title {
  width: 100%;
  height: 40px;
  line-height: 20px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.box:hover .title {
  color: #78b5f9;
}
.tag p {
  margin-top: 0;
}
.auditionContent {
  min-height: 460px;
}
.duration {
  position: absolute;
  right: 10px;
  top: 124px;
  color: #fff;
}
</style>
